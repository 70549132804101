<template>
  <div class="user-logged">
    <p class="balance">{{ $formatNumberWithCommas(store.currentUser?.balance, '.') }} G</p>
    <nuxt-link class="avatar" :to="PAGE.ACCOUNT">
      <div class="avatar-name">{{ store.currentUser?.fullname?.substring(0, 1) || 'U' }}</div>
    </nuxt-link>
    <nuxt-link id="header-deposit-btn" class="btn-deposit" :to="PAGE.ACCOUNT_DEPOSIT">
      <BaseImg src="/assets/images/components/mobile/icon-deposit.svg" alt="icon deposit" />
      <span>Nạp</span>
    </nuxt-link>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
import { PATH_AVATAR_URL } from '~/constants/path'
import { useAccountStore } from '~~/store/account'
import { useToggleMenu } from '~/composables/useToggleMenu'
import BaseButton from '~/components/common/base-button.vue'
import BaseImg from '~/components/common/base-img.vue'
import { PAGE } from '~/constants/router'

const { $pinia, $formatAmountUnit } = useNuxtApp()
const store = useAppStore($pinia)
const { setDepositFrom } = store
const accountStore = useAccountStore()
const { toggleMenuAccount, toggleMenuDeposit } = useToggleMenu()
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/header/user-logged.scss" />
